/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useParams } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { fadeIn } from '../animations'

const SingleDomain = () => {
  const { domain } = useParams()

  return (
    <div
      css={css`
        max-width: 40rem;
        margin: 0 auto;
        animation: ${fadeIn} 0.3s ease-out forwards;

        & > section {
          margin-top: 2rem;
          border-top: 1px solid var(--secondary);
        }
      `}
    >
      <h2>{domain}</h2>

      <section>
        <h4>Entfernen</h4>
        <p>Dieso Domain nicht mehr in ihrem Dashboard anzeigen.</p>
        <Button variant="outlined">Entfernen</Button>
      </section>

      <section>
        <h4>Löschen</h4>
        <p>Diese Domain und alle gespeichertern Daten Löschen.</p>
        <Button variant="outlined">Löschen</Button>
      </section>
    </div>
  )
}

export default SingleDomain
