import { useState, useCallback } from 'react'
import firebase from '../firebase'
import { useHistory, useLocation } from 'react-router-dom'

const useSignIn = () => {
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation<{
    from: {
      pathname: string
    }
  }>()

  const { from } = location.state || { from: { pathname: '/' } }

  const signIn = useCallback(
    async (email: string, password: string) => {
      setIsSigningIn(true)
      let response
      try {
        response = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
        history.replace(from)
      } catch (error) {
        setIsError(response === undefined)
        console.error(error)
      }

      setIsSigningIn(false)
    },
    [from, history]
  )

  return { isSigningIn, isError, signIn }
}

export default useSignIn
