/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReferrerWithCount } from '../types/Insights'
import { Fragment } from 'react'
import { byCount, onlyN } from '../util/filter'
import DashboardListItem from '../components/DashboardListItem'

const maxRefferers = 10

const Referrer = ({ referrers }: { referrers: ReferrerWithCount[] }) => {
  return (
    <Fragment>
      <h4>Quellen</h4>
      <ul>
        {referrers
          .sort(byCount)
          .filter(onlyN(maxRefferers))
          .map((referrer) => (
            <DashboardListItem
              key={referrer.referrer}
              count={referrer.count}
              text={referrer.referrer}
            />
          ))}
      </ul>
      {referrers.length > maxRefferers && <small>Die Top {maxRefferers}</small>}
    </Fragment>
  )
}

export default Referrer
