import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#e32ef3',
    },
  },
})

export default theme
