import { useState, useEffect } from 'react'
import { InsightsResponse } from '../types/Insights'
import { LOADING_STATE } from '../types/types'
import useRequest from '../util/useRequest'

const useInsights = (hostname: string) => {
  const [insights, setInsights] = useState<InsightsResponse | undefined>(
    undefined
  )
  const [loadingState, setLoadingState] = useState<LOADING_STATE>('IDLE')
  const { request } = useRequest()

  useEffect(() => {
    const getData = async () => {
      setLoadingState('LOADING')
      const baseUrl =
        window.location.hostname === 'localhost'
          ? `http://localhost:${window.location.port}`
          : ''
      try {
        const { data, isSuccess, statusCode } = await request<InsightsResponse>(
          `${baseUrl}/api/insights?hostname=${hostname}`
        )

        // Handle errors.
        if (!isSuccess) {
          if (statusCode === 403) {
            return setLoadingState('UNAUTHORIZED')
          }
          return setLoadingState('ERROR')
        }

        // Store data.
        setLoadingState('FINISHED')
        setInsights(data)
      } catch (e) {
        setLoadingState('ERROR')
      }
    }
    getData()
  }, [setInsights, hostname, request])

  return { loadingState, insights }
}

export default useInsights
