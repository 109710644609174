/**
 * Private route.
 * After the recipe at:
 * https://reacttraining.com/react-router/web/example/auth-workflow
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Route, Redirect, RouteProps } from 'react-router'
import useAccount from './account/useAccount'
import { ComponentType } from 'react'

const ProtectedRoute: ComponentType<RouteProps> = ({ children, ...rest }) => {
  const { isLoggedIn, isInitiallyLoading } = useAccount()

  if (isInitiallyLoading) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <div
            css={css`
              max-width: 60rem;
              margin: 0 auto;
              padding: 2rem;
            `}
          >
            {children}
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
