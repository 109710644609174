/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import useAccount from './account/useAccount'

const Header = () => {
  const { signOut } = useAccount()

  return (
    <header
      css={css`
        display: flex;
        align-content: center;
      `}
    >
      <h1
        css={css`
          max-width: 60rem;
          margin: 0 auto;
          flex-grow: 1;
        `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: var(--primary);
          `}
          to="/"
        >
          NeoAnalytics{' '}
          <span role="img" aria-label="Femalte rocker emoji">
            👩‍🎤
          </span>
        </Link>
      </h1>

      {/* <div
        css={css`
          display: flex;
          align-items: center;
          margin-right: 0.33rem;
        `}
      >
        <a
          href="https://docs.neoanalytics.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          docs
        </a>
      </div> */}
      <Button onClick={signOut}>Logout</Button>
    </header>
  )
}

export default Header
