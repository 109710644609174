import firebase from '../firebase'
import { useEffect, useState, useCallback } from 'react'
import useAccount from '../account/useAccount'
import useRequest from '../util/useRequest'
import { useHistory } from 'react-router-dom'

export interface Domain {
  domain: string
}

const useDomains = () => {
  const [domains, setDomains] = useState<Domain[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { uid } = useAccount()
  const { request } = useRequest()
  const { push } = useHistory()

  useEffect(() => {
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .onSnapshot((snapshot) => {
        setIsLoaded(true)
        const data = snapshot.data()
        if (data) {
          setDomains(data.domains as Domain[])
        }
      })
  }, [uid, setDomains, setIsLoaded])

  const register = useCallback(
    async (domain) => {
      try {
        const { isSuccess } = await request('/api/addDomain', {
          method: 'POST',
          body: JSON.stringify({
            domain,
          }),
        })
        if (!isSuccess)
          return alert(
            'Ein Fehler ist aufgetreten, bitte versuche es später wieder.'
          )
        push('/')
      } catch (error) {
        console.log(error)
      }
    },
    [request, push]
  )

  return {
    isLoaded,
    domains,
    register,
  }
}

export default useDomains
