/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const DashboardListItem = ({
  count,
  text,
  wide,
}: {
  count: string | number
  text: string
  wide?: boolean
}) => {
  return (
    <li
      css={css`
        margin-top: 0.2rem;

        /* Text might be too long for a single row  but we want only one. */
        word-break: break-all;
        overflow: hidden;
        height: 1.5em;
      `}
    >
      <span
        css={css`
          display: inline-block;
          width: ${wide ? '2.5' : '2'}rem;
        `}
      >
        {count}
      </span>
      <span>{text}</span>
    </li>
  )
}

export default DashboardListItem
