/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import LoadingSquare from '../components/loadingSquare'
import useInsights from './useInsights'
import InsightsDisplay from './InsightsDisplay'
import { Fragment } from 'react'
import Insights404 from './Insights404'
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'

const Insights = ({ hostname }: { hostname: string }) => {
  const { insights, loadingState } = useInsights(hostname)

  if (loadingState === 'LOADING' || loadingState === 'IDLE')
    return (
      <div
        css={css`
          display: flex;
          height: 30rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <LoadingSquare />
      </div>
    )

  if (loadingState === 'UNAUTHORIZED') return <Insights404 domain={hostname} />

  if (loadingState === 'ERROR')
    return (
      <div
        css={css`
          margin-top: 2rem;
        `}
      >
        <Alert severity="error">
          Beim Laden Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie
          es erneut.
        </Alert>
      </div>
    )

  const totalSessions =
    (insights &&
      insights.visitCounts.reduce(
        (count, gust) => count + gust.sessionsCount,
        0
      )) ||
    0

  if (totalSessions === 0)
    return (
      <div
        css={css`
          margin-top: 2rem;
        `}
      >
        <Alert severity="info">
          Es sind noch keine Daten für diese Domain vorhanden.
        </Alert>
        <br />
        <Button onClick={() => window.document.location.reload()}>
          Neu Laden
        </Button>
      </div>
    )

  if (!insights) return null

  return (
    <Fragment>
      <h4>{totalSessions} Sessions im letzten Monat.</h4>
      <br />
      <InsightsDisplay data={insights} />
    </Fragment>
  )
}

export default Insights
