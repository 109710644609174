/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { bounceInDown } from '../animations'

const Page404 = () => (
  <div
    css={css`
      animation: ${bounceInDown} 0.3s ease-out forwards;
    `}
  >
    <p
      css={css`
        font-size: 13rem;
        color: var(--primary);
        text-shadow: 0px 0px 1px white, 0px 0px 10px var(--primary);
        margin-bottom: 0;
        font-weight: 300;
        text-align: center;
      `}
    >
      404
    </p>
    <p
      css={css`
        max-width: 30rem;
        font-size: 1.33em;
        margin: 0 auto;
        text-align: center;
      `}
    >
      Diese Seite scheint es nicht zu geben. Besuche am besten die{' '}
      <a href="/">Startseite</a>.
    </p>
  </div>
)

export default Page404
