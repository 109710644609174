/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ComponentType } from 'react'

export interface CardAttributes {
  gridArea?: string
}

const Card: ComponentType<CardAttributes> = ({ children, gridArea }) => {
  return (
    <div
      css={css`
        border-radius: 0.33rem;
        background-color: var(--elevation-1);
        box-shadow: 0 1px 10px rgba(0, 181, 255, 0.24),
          0 1px 6px rgba(0, 181, 255, 0.48);
        border: 2px solid #00b5ff;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        ${gridArea &&
        css`
          grid-area: ${gridArea};
        `}
        & > *:first-of-type {
          margin-top: 0;
          padding-top: 0;
        }
      `}
    >
      {children}
    </div>
  )
}

export default Card
