/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PathWithCount } from '../types/Insights'
import { Fragment } from 'react'
import { byCount, onlyN } from '../util/filter'
import DashboardListItem from '../components/DashboardListItem'

const maxPaths = 10

const Paths = ({ paths }: { paths: PathWithCount[] }) => {
  return (
    <Fragment>
      <h4>Pfade</h4>
      <ul>
        {paths
          .sort(byCount)
          .filter(onlyN(maxPaths))
          .map((path) => (
            <DashboardListItem
              key={path.path}
              count={path.count}
              text={path.path}
            />
          ))}
      </ul>
      {paths.length > maxPaths && <small>Die Top {maxPaths}.</small>}
    </Fragment>
  )
}

export default Paths
