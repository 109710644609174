/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { byCount } from '../util/filter'
import { BrowserWithCount } from '../types/Insights'
import DashboardListItem from '../components/DashboardListItem'

const Browsers = ({ browsers }: { browsers: BrowserWithCount[] }) => {
  return (
    <Fragment>
      <h4>Browser</h4>
      <ul>
        {browsers.sort(byCount).map((browser) => (
          <DashboardListItem
            key={browser.browser}
            count={browser.count}
            text={browser.browser}
          />
        ))}
      </ul>
    </Fragment>
  )
}

export default Browsers
