/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import TextField from '@material-ui/core/TextField'
import { Button, LinearProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useSignIn from './useSignIn'
import Alert from '@material-ui/lab/Alert'

const Login = () => {
  const { isSigningIn, signIn, isError } = useSignIn()

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Bitte gib eine gültige E-Mail ein.')
      .required('Pflichtfeld'),
    password: Yup.string().required('Pflichtfeld'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      signIn(values.email, values.password)
    },
    validateOnChange: false,
  })

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        height: 100vh;
        width: 100vw;
        @media (max-width: 50rem) {
          grid-auto-columns: 1fr;
        }
      `}
    >
      <div
        css={css`
          display: block;
          background-image: url('/img/login.webp');
          opacity: 0.66;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          @media (max-width: 50rem) {
            display: none;
          }
        `}
      ></div>
      <div
        css={css`
          padding: 2rem;
          display: flex;
          flex-direction: column;
          min-height: 100vh;
          box-sizing: border-box;
        `}
      >
        <h1
          css={css`
            color: var(--primary);
            text-align: center;
            font-size: 4rem;
            text-shadow: 0px 0px 10px var(--primary);
            margin-bottom: 2rem;
          `}
        >
          NeoAnalytics
        </h1>

        <div
          css={css`
            margin-bottom: 1rem;
            height: 3rem;
          `}
        >
          {isError && (
            <Alert severity="error">
              Login fehlgeschlagen. Bitte überprüfe deine Daten.
            </Alert>
          )}
        </div>

        <form
          css={css`
            flex-grow: 1;
          `}
          onSubmit={formik.handleSubmit}
        >
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <TextField
              type="text"
              name="email"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Email"
              fullWidth
              autoFocus
              autoComplete="email"
              helperText={formik.errors.email || ' '}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <TextField
              type="password"
              name="password"
              label="Passwort"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="current-password"
              fullWidth
              helperText={formik.errors.password || ' '}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </div>
          <Button
            type="submit"
            disabled={isSigningIn}
            size="large"
            variant="outlined"
            color="primary"
          >
            <div>
              Login
              {isSigningIn && <LinearProgress />}
            </div>
          </Button>
        </form>

        <div>
          <p>
            Erfahre mehr über NeoAnalytics auf{' '}
            <a href="https://neoanalytics.de">NeoAnalytics.de</a>.
          </p>
          {/* <p>
            Besuche unsere{' '}
            <a href="https://docs.neoanalytics.de">Dokumentation</a>.
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default Login
