/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './Home'
import Header from './Header'
import ProtectedRoute from './ProtectedRoute'
import Login from './account/Login'
import AddDomain from './domains/AddDomain'
import Page404 from './components/Page404'
import SingleDomain from './domains/SingleDomain'
import Dashboard from './analyse/Dashboard'

function App() {
  return (
    <Router>
      <Global
        styles={css`
          :root {
            /* --background: #161316; */
            --background: #292329;
            --text: #ffffff;
            --background-colored: #3d2272;
            --pink: #e32ef3;
            --blue: #00b5ff;
            --purple: #7640dd;
            /* Following: https://material.io/design/color/dark-theme.html#properties */
            --elevation-1: #221f22;
            --elevation-2: #302d30;

            --primary: var(--pink);
            --secondary: var(--blue);
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            font-weight: 300;
          }

          h1 {
            text-shadow: 0px 0px 2px var(--primary);
          }

          ul {
            list-style-type: none;
            padding: 0;
          }

          html,
          body {
            margin: 0;
            padding: 0;
          }

          body {
            font-family: 'Roboto', sans-serif;
            color: var(--text);
            background-color: var(--background);
          }

          a {
            color: var(--blue);
          }

          h1 {
            font-size: 2.25rem;
          }

          h2 {
            font-size: 1.85rem;
          }

          h3 {
            font-size: 1.55rem;
          }

          h4 {
            font-size: 1.25rem;
          }

          h5 {
            font-size: 1rem;
          }

          h6 {
            font-size: 0.875rem;
          }

          p {
            line-height: 133%;
          }
        `}
      />

      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <ProtectedRoute exact path="/">
          <Home />
        </ProtectedRoute>
        <ProtectedRoute path="/insights/:hostname">
          <Header />
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute path="/domain/add">
          <Header />
          <AddDomain />
        </ProtectedRoute>
        <ProtectedRoute path="/domain/:domain">
          <Header />
          <SingleDomain />
        </ProtectedRoute>
        {/* 404 for logged in state. Logged out users will get directed to login always. */}
        <ProtectedRoute path="*">
          <Header />
          <Page404 />
        </ProtectedRoute>
      </Switch>
    </Router>
  )
}

export default App
