import React, { useEffect } from 'react'
import { createContext, ComponentType, useState } from 'react'
import firebase from '../firebase'

export interface AccountContextValue {
  isLoggedIn: boolean
  isInitiallyLoading: boolean
  idToken?: string
  uid?: string
}

export const AccountContext = createContext<AccountContextValue>({
  isLoggedIn: false,
  isInitiallyLoading: true,
})

const AccountProvider: ComponentType = ({ children }) => {
  const [user, setUser] = useState<any>({})
  const [idToken, setIdToken] = useState<string | undefined>()
  const [isLoggedIn, setIsLogedIn] = useState<boolean>(false)
  const [isInitiallyLoading, setIsInitiallyLoading] = useState<boolean>(true)

  const value: AccountContextValue = {
    uid: user?.uid,
    isLoggedIn,
    isInitiallyLoading,
    idToken,
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
        setIsLogedIn(true)
        // Also save the token.
        firebase
          .auth()
          .currentUser?.getIdToken(true)
          .then((idToken) => {
            setIdToken(idToken)
            setIsInitiallyLoading(false)
          })
          .catch(function (error) {
            setIsInitiallyLoading(false)
            /* No idea how to handle this yet. */
          })
      } else {
        setIsLogedIn(false)
        setUser(undefined)
        setIsInitiallyLoading(false)
      }
    })
  }, [])

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}

export default AccountProvider
