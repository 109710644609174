import { keyframes } from '@emotion/core'

export const fadeInDown = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -3rem, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}`

export const fadeIn = keyframes`
from {
  opacity: 0;
}

tp {
  opacity: 1;
}
`

export const bounceInDown = keyframes`
from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -30px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`
