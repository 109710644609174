import firebase from '../firebase'

interface RequestResponse<T> {
  data?: T
  statusCode: number
  isSuccess: boolean
}

const request = async <T>(url: RequestInfo, config?: RequestInit) => {
  const idToken = await firebase.auth().currentUser?.getIdToken(true)
  const fetchConfig = {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: idToken || '',
    },
  }
  const response = await fetch(url, fetchConfig)

  const result: RequestResponse<T> = {
    isSuccess: response.ok,
    statusCode: response.status,
  }

  if (response.ok) {
    result.data = (await response.json()) as T
  }

  return result
}

const useRequest = () => ({
  request,
})

export default useRequest
