import React from 'react'
import { hydrate, render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/core'
import theme from './muiTheme'
import AccountProvider from './account/AccountProvider'

const AppComponent = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AccountProvider>
        <App />
      </AccountProvider>
    </ThemeProvider>
  </React.StrictMode>
)

const rootElement = document.getElementById('root')

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<AppComponent />, rootElement)
} else {
  render(<AppComponent />, rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Hightlight development tab.
if (process?.env?.NODE_ENV === 'development') {
  document.title = `[DEV] ${document.title}`
}
