// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app'

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyC7c4LXPn-t7IhEj85_kvgNSEAjC-RFFU8',
  authDomain: 'neoanalytics-df2cc.firebaseapp.com',
  databaseURL: 'https://neoanalytics-df2cc.firebaseio.com',
  projectId: 'neoanalytics-df2cc',
  storageBucket: 'neoanalytics-df2cc.appspot.com',
  messagingSenderId: '903167460204',
  appId: '1:903167460204:web:9a7dcd777531cc3ac66949',
}

firebase.initializeApp(firebaseConfig)

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .then(() => console.log('Persistance: enabled successfully.'))
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.error('Persistance: already running in another tab.')
    } else if (err.code === 'unimplemented') {
      console.error('Persistance: preconditions not fulfilled. ')
    }
  })

export default firebase
