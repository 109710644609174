/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'

const loading = keyframes`
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
    
  } 
  70% {
    box-shadow: 0 0 10px 2px var(--primary);
    
  }
  35% {
    transform: scale3D(0, 0, 1);
    
  }`

const LoadingSquare = () => (
  <div
    css={css`
      width: 40px;
      height: 40px;
      div {
        width: 33%;
        height: 33%;
        background-color: var(--primary);
        float: left;
        animation: ${loading} 1.66s infinite ease-in-out;
      }
      div:nth-of-type(7) {
        animation-delay: 0s;
      }
      div:nth-of-type(4),
      div:nth-of-type(8) {
        animation-delay: 0.1s;
      }
      div:nth-of-type(1),
      div:nth-of-type(5),
      div:nth-of-type(9) {
        animation-delay: 0.2s;
      }
      div:nth-of-type(2),
      div:nth-of-type(6) {
        animation-delay: 0.3s;
      }
      div:nth-of-type(3) {
        animation-delay: 0.4s;
      }
    `}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default LoadingSquare
