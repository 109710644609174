import { useContext, useCallback, useMemo } from 'react'
import { AccountContext, AccountContextValue } from './AccountProvider'
import firebase from '../firebase'

const useAccount = () => {
  const contextValue: AccountContextValue = useContext(AccountContext)

  const signOut = useCallback(() => {
    firebase.auth().signOut()
  }, [])

  const value = useMemo(
    () => ({
      signOut,
      ...contextValue,
    }),
    [signOut, contextValue]
  )

  return value
}

export default useAccount
