/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextField, Button } from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useDomains from './useDomains'

const AddDomainSchema = Yup.object().shape({
  domain: Yup.string()
    .lowercase()
    // Domains only need to contain a "." somewhere.
    // Backend will validate the rest 😎
    .matches(/^.+?\..+$/, 'Bitte gib eine gültige Domain ein')
    .required(),
})

const AddDomain = () => {
  const { register } = useDomains()
  const formik = useFormik({
    initialValues: {
      domain: '',
    },
    validationSchema: AddDomainSchema,
    onSubmit: ({ domain }) => {
      register(domain)
    },
    validateOnChange: false,
  })

  return (
    <div>
      <h2>Domain hinzufügen</h2>
      <form onSubmit={formik.handleSubmit}>
        <div
          css={css`
            margin-top: 2rem;
            margin-bottom: 0.5rem;
          `}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Neue Domain"
            variant="outlined"
            fullWidth
            autoFocus
            placeholder="Beispiel.de"
            name="domain"
            id="domain"
            helperText={formik.errors.domain || ' '}
            value={formik.values.domain}
            onChange={formik.handleChange}
          />
        </div>

        <Button type="submit" size="large" variant="outlined" color="primary">
          <div>Hinzufügen</div>
        </Button>
      </form>
    </div>
  )
}

export default AddDomain
