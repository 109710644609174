/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4themes_dark from '@amcharts/amcharts4/themes/dark'
import { useRef, useEffect } from 'react'
import useAMChart from './useAMChart'

am4core.useTheme(am4themes_dark)
am4core.useTheme(am4themes_animated)

const am4themes_neon_pink: am4core.ITheme = (target) => {
  if (target instanceof am4core.ColorSet) {
    target.list = [am4core.color('#00b5ff')]
  }

  if (target instanceof am4core.Circle) {
    target.stroke = am4core.color('#fff')
    target.strokeWidth = 2
  }
}
am4core.useTheme(am4themes_neon_pink)

const Graph = ({ data }: { data: any }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chart = useAMChart(chartRef, am4charts.XYChart)

  useEffect(() => {
    if (!chart) return

    chart.data = data
    chart.dateFormatter.inputDateFormat = 'yyyy.MM.dd'
    chart.paddingRight = 20

    chart.events.on('beforedatavalidated', function (ev) {
      chart.data.sort(function (a, b) {
        return new Date(a.date).valueOf() - new Date(b.date).valueOf()
      })
    })

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.zoomable = false
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    if (valueAxis && valueAxis.tooltip) {
      // This disabled the toolstip on the Axis.
      valueAxis.tooltip.disabled = true
    }

    // Disable lines going to the axis.
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.lineY.opacity = 0

    // Configer how to parse the provided data.
    const series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.dateX = 'date'
    series.dataFields.valueY = 'totalCount'
    series.tooltipHTML = `
    <table>
      <tr>
        <th align="left">Aufrufe</th>
        <td>{totalCount}</td>
      </tr>
      <tr>
        <th align="left">Sessions</th>
        <td>{sessionsCount}</td>
      </tr>
    </table>`
    series.fillOpacity = 0.3
    series.stroke = am4core.color('#00b5ff')
    const shadow = series.filters.push(new am4core.DropShadowFilter())
    shadow.dx = 0
    shadow.dy = 0
    shadow.height = 200
    shadow.width = 200
    shadow.blur = 30
    shadow.color = am4core.color('#00b5ff')

    // Only show bullets on hover
    const bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.fillOpacity = 0
    bullet.strokeOpacity = 0
    const bullethover = bullet.states.create('hover')
    bullethover.properties.fillOpacity = 1
    bullethover.properties.strokeOpacity = 1

    // Also display unique visitors
    let uniqueSeries = chart.series.push(new am4charts.LineSeries())
    uniqueSeries.dataFields.dateX = 'date'
    uniqueSeries.dataFields.valueY = 'sessionsCount'
    uniqueSeries.fillOpacity = 0.2
    uniqueSeries.stroke = am4core.color('#fff')
    const uniqueShadow = uniqueSeries.filters.push(
      new am4core.DropShadowFilter()
    )
    uniqueShadow.dx = 0
    uniqueShadow.dy = 0
    uniqueShadow.height = 200
    uniqueShadow.width = 200
    uniqueShadow.blur = 30
    uniqueShadow.color = am4core.color('#00b5ff')

    // OnHover bullets for unique visitors series.
    const uniqueBullet = uniqueSeries.bullets.push(new am4charts.CircleBullet())

    uniqueBullet.fillOpacity = 0
    uniqueBullet.strokeOpacity = 0
    const uniqueBullethover = uniqueBullet.states.create('hover')
    uniqueBullethover.properties.fillOpacity = 1
    uniqueBullethover.properties.strokeOpacity = 1
  }, [chart, data])

  return (
    <div
      css={css`
        height: 30rem;
        max-height: 100vh;

        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td {
          margin: 0;
          padding: 0;
          border: 0;
          outline: 0;
          font-size: 100%;
          font-weight: normal;
          vertical-align: baseline;
          background: transparent;
        }
        th {
          padding-right: 0.33rem;
        }
      `}
      ref={chartRef}
    >
      Chart
    </div>
  )
}

export default Graph
