/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { OperatingSystemWithCount } from '../types/Insights'
import DashboardListItem from '../components/DashboardListItem'
import { byCount, onlyN } from '../util/filter'

const MAX_OPERATING_SYSTEMS = 10

const OperatingSystems = ({
  operatingSystems,
}: {
  operatingSystems: OperatingSystemWithCount[]
}) => {
  const totalCount = operatingSystems.reduce((count, os) => count + os.count, 0)

  return (
    <Fragment>
      <h4>Betriebssysteme</h4>
      <ul>
        {operatingSystems
          .sort(byCount)
          .filter(onlyN(MAX_OPERATING_SYSTEMS))
          .map((os) => (
            <DashboardListItem
              key={`${os.os}-${os.count}`}
              wide
              count={`${Math.round((os.count / totalCount) * 100)}%`}
              text={os.os}
            />
          ))}
      </ul>
      {operatingSystems.length > MAX_OPERATING_SYSTEMS && (
        <small>Die top {MAX_OPERATING_SYSTEMS}.</small>
      )}
    </Fragment>
  )
}

export default OperatingSystems
