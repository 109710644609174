/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import { Fragment } from 'react'
import useDomains from './useDomains'
import { CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
// import LinkButton from '../components/LinkButton'
import SetupInfo from './SetupInfo'

const fadeIn = keyframes`
  0%{
    transform: translateX(-1rem)
  }
  100%{
    transform: translateX(0)
  }
`

const Domains = () => {
  const { domains, isLoaded } = useDomains()

  return (
    <div
      css={css`
        max-width: 40rem;
        margin: 0 auto;
      `}
    >
      <SetupInfo />

      <hr />

      <h2>Domains</h2>
      {!isLoaded && <CircularProgress />}
      {isLoaded && (
        <Fragment>
          <ul
            css={css`
              animation: ${fadeIn} 0.3s ease-out forwards;
              margin-bottom: 2rem;
            `}
          >
            {domains.map((domain) => (
              <li
                css={css`
                  line-height: 2.5em;
                  display: flex;
                  &:hover {
                    background-color: var(--elevation-2);
                  }
                  a {
                    display: block;
                  }
                  & > a:first-of-type {
                    flex-grow: 1;
                  }
                  & > button {
                    opacity: 0.3;
                    transition: all 0.3s ease-out;
                  }
                  &:hover > button {
                    opacity: 1;
                    transition: 0;
                  }
                `}
                key={domain.domain}
              >
                <Link to={`/insights/${domain.domain}`}>{domain.domain}</Link>

                {/* <LinkButton to={`/domain/${domain.domain}`}>
                  verwalten
                </LinkButton> */}
              </li>
            ))}
          </ul>

          {/* <Link to="/domain/add">
            <Button variant={domains.length > 0 ? 'outlined' : 'contained'}>
              Domain hinzufügen
            </Button>
          </Link> */}
        </Fragment>
      )}
    </div>
  )
}

export default Domains
