/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { ResolutionWithCount } from '../types/Insights'
import DashboardListItem from '../components/DashboardListItem'

const Resolutions = ({ resolutions }: { resolutions: ResolutionWithCount }) => {
  const totalCount =
    resolutions.phone +
    resolutions.tablet +
    resolutions.laptop +
    resolutions.desktop

  return (
    <Fragment>
      <h4>Geräte</h4>
      <ul>
        <DashboardListItem
          wide
          count={`${Math.round((resolutions.phone / totalCount) * 100)}%`}
          text="Smartphones"
        />
        <DashboardListItem
          wide
          count={`${Math.round((resolutions.tablet / totalCount) * 100)}%`}
          text="Tablets"
        />
        <DashboardListItem
          wide
          count={`${Math.round((resolutions.laptop / totalCount) * 100)}%`}
          text="Laptops"
        />
        <DashboardListItem
          wide
          count={`${Math.round((resolutions.desktop / totalCount) * 100)}%`}
          text="Desktops"
        />
      </ul>
    </Fragment>
  )
}

export default Resolutions
