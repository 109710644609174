/** @jsx jsx */
import { jsx } from '@emotion/core'
import Login from './account/Login'
import useAccount from './account/useAccount'
import Header from './Header'
import Domains from './domains/Domains'

const Home = () => {
  const { isLoggedIn, isInitiallyLoading } = useAccount()

  if (isInitiallyLoading) return null

  if (!isLoggedIn) return <Login />

  return (
    <div>
      <Header />
      <Domains />
    </div>
  )
}

export default Home
