/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const SetupInfo = () => (
  <section>
    <h2>Setup</h2>
    <p>
      Um NeoAnalytics in Ihrer Website zu integrieren, fügen Sie einfach das
      folgende Script-Tag am Ende ihres head ein.
    </p>

    <pre
      css={css`
        word-break: break-all;
        white-space: break-spaces;
      `}
    >
      &lt;script async defer
      src="https://app.neoanalytics.de/neoClient.js"&gt;&lt;/script&gt;
    </pre>

    <p>
      NeoAnalytics übernimmt nun automatisch das anonyme sammeln von Besuchen
      ihrer Website.
    </p>

    <p>
      Um die Daten anschließen über dieses Dashboard zu erreichen, wenden Sie
      sich bitte an uns, damit wir Sie freischalten können.
    </p>
  </section>
)

export default SetupInfo
