/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useParams } from 'react-router-dom'
import Insights from './Insights'

type UseParamType = {
  hostname: string
}

const Dashboard = () => {
  const { hostname } = useParams<UseParamType>()

  return (
    <div>
      <h2>
        Überblick für{' '}
        <a
          href={`https://${hostname}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {hostname}
        </a>
      </h2>
      <Insights hostname={hostname} />
    </div>
  )
}

export default Dashboard
