/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import Graph from './Graph'
import Referrer from './Referrer'
import Paths from './Paths'
import Browsers from './Browsers'
import Resolutions from './Resolutions'
import Card from '../components/Card'
import { InsightsResponse } from '../types/Insights'
import { fadeInDown } from '../animations'
import OperatingSystems from './OperatingSystems'

const InsightsDisplay = ({ data }: { data: InsightsResponse }) => {
  return (
    <div
      css={css`
        animation: ${fadeInDown} 0.3s ease-out forwards;
      `}
    >
      <div
        css={css`
          margin-bottom: 2rem;
        `}
      >
        <Graph data={data.visitCounts} />
      </div>
      <div
        css={css`
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: auto;
          grid-template-areas:
            'sources sources sources paths paths paths'
            'browsers browsers resolutions resolutions operatingSystems operatingSystems';
          gap: 1.5rem 1.5rem;
          font-weight: 300;
          @media (max-width: 50rem) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:
              'sources'
              'paths'
              'resolutions'
              'browsers'
              'operatingSystems';
          }
        `}
      >
        <Card gridArea="sources">
          <Referrer referrers={data.referrers} />
        </Card>
        <Card gridArea="paths">
          <Paths paths={data.paths} />
        </Card>
        <Card gridArea="browsers">
          <Browsers browsers={data.browsers} />
        </Card>
        <Card gridArea="resolutions">
          <Resolutions resolutions={data.resolutions} />
        </Card>
        <Card gridArea="operatingSystems">
          <OperatingSystems operatingSystems={data.operatingSystems} />
        </Card>
      </div>
    </div>
  )
}

export default InsightsDisplay
